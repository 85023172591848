@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "RubikMonoOne";
  src: url("./assets/webfonts/RubikMonoOne-Regular.ttf");
}

/* @font-face {
  font-family: "Comfortaa";
  src: url("./assets/webfonts/Comfortaa-Regular.ttf");
} */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;600;700&display=swap');

@layer utilities {
  .transition-smooth {
    transition: 200ms ease-in-out;
  }
}


html {
  font-size: 18px;
  font-family: "Comfortaa";
}

a.active > div {
  background-color: black;
}

.iconAccent:hover {
  filter: invert(48%) sepia(88%) saturate(3054%) hue-rotate(347deg) brightness(100%) contrast(102%);
}

.grecaptcha-badge {
  visibility: hidden;
}

.zoomIn {
  transition: 0.5s;
  opacity: 0;
  transform: scale(0);
}

.zoomIn.load-triggered {
  opacity: 1;
  transform: unset;
}

.megaZoomIn {
  animation: 0.5s megaZoomIn forwards;
}

.megaZoomOut {
  animation: 0.5s megaZoomOut forwards;
}


@keyframes megaZoomIn {
 from {
   opacity: 0;
   transform: scale(5);
 }

 to {
   opacity: 1;
   transform: scale(1);
 }
}

@keyframes megaZoomOut {
 from {
   opacity: 1;
   transform: scale(1);
 }

 to {
   opacity: 0;
   transform: scale(0);
 }
}

.fadeIn {
  animation: 0.35s fadeIn forwards;
}

.fadeOut {
  animation: 0.35s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: unset;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: unset;
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}